import React from 'react';
import './App.css';
import { Admin, Resource } from "react-admin";
import CategoryIcon from '@material-ui/icons/Category'
import VideoIcon from '@material-ui/icons/Videocam'
import UserIcon from '@material-ui/icons/Person'
import ChatroomIcon from '@material-ui/icons/Chat'
import FAQIcon from '@material-ui/icons/QuestionAnswer'
import authProvider from "./providers/auth-provider";
import useDataProvider from "./providers/data-provider";
import theme from "./theme";
import AppLayout from "./layout/MainLayout/Layout";
import LoginLayout from "./layout/loginLayout";
import { CategoryCreate, CategoryEdit, CategoryList, CategoryShow } from './modules/Category';
import { VideoCreate, VideoEdit, VideoList, VideoShow } from './modules/Video';
import { UserCreate, UserEdit, UserList } from './modules/User';
import { FaqCreate, FaqEdit, FaqList, FaqShow } from "./modules/Faq";
import { ChatroomCreate, ChatroomEdit, ChatroomList, ChatroomShow } from "./modules/Chatroom";

const App = () => {
    const provider = useDataProvider(authProvider.getToken)

    return <Admin
        title="Peachy Religion Admin Portal"
        dataProvider={provider}
        authProvider={authProvider}
        theme={theme}
        loginPage={LoginLayout}
        layout={AppLayout}
    >
        <Resource
            icon={UserIcon}
            name="user"
            options={{ label: 'Users' }}
            list={UserList}
            create={UserCreate}
            edit={UserEdit}
            />

        <Resource
            icon={ChatroomIcon}
            name="chatroom"
            options={{ label: 'Chat Rooms' }}
            list={ChatroomList}
            create={ChatroomCreate}
            edit={ChatroomEdit}
            show={ChatroomShow}
            />

        <Resource
            icon={VideoIcon}
            name="video"
            options={{ label: 'Videos' }}
            list={VideoList}
            create={VideoCreate}
            edit={VideoEdit}
            show={VideoShow}
        />

        <Resource
            icon={CategoryIcon}
            name="category"
            options={{ label: 'Categories' }}
            list={CategoryList}
            create={CategoryCreate}
            edit={CategoryEdit}
            show={CategoryShow}
        />
        <Resource
            icon={FAQIcon}
            name="faq"
            options={{ label: 'FAQ' }}
            list={FaqList}
            create={FaqCreate}
            edit={FaqEdit}
            show={FaqShow}
            />

    </Admin>
}

export default App;
