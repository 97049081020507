import axios from "axios";
import * as tus from 'tus-js-client';

const vimeoProvider = () => {
    return {
        upload: async (file: File, uploadUrl: string) => {
            const upload = new tus.Upload(file, {
                endpoint: 'https://api.vimeo.com/me/videos',
                uploadUrl,
                retryDelays: [ 0, 3000, 5000, 10000, 20000 ],
                metadata: {
                    filename: file.name,
                    filetype: file.type,
                },
                headers: {},
                onError: function (error: Error) {
                    console.log('Failed because: ' + error);
                },
                onProgress: function (bytesUploaded: number, bytesTotal: number) {
                    let percentage = ( ( bytesUploaded / bytesTotal ) * 100 ).toFixed(2);
                    console.log(bytesUploaded, bytesTotal, percentage + '%');
                },
                onSuccess: function () {
                    if ( "name" in upload.file ) {
                        console.log('Download %s from %s', upload.file.name, upload.url);
                    }
                }
            });

            // Start the upload
            upload.start();
        },
        uploadThumbnail: async (link: string, file: File) => {
            await axios.put(link, file, {
                headers: { Accept: 'application/vnd.vimeo.*+json;version=3.4', 'Content-Type': file.type }
            })
        },
    }
}

export default vimeoProvider
