import { FaQ, FaQApi } from "@lj-island/peachy-religion-client";
import { useMemo, useRef } from "react"
import { SimpleDataProvider } from "./types";

const useFaqsProvider: SimpleDataProvider = (config) => {
    const faqsApi = useMemo(() => new FaQApi(config), [ config ])

    const faqsCache = useRef<FaQ[]>([])

    return {
        getList: async function (_, params) {
            const { data } = await faqsApi.getFaqs('true', params.pagination.page, params.pagination.perPage)
            faqsCache.current = data.faqs.map(c => ( { ...c } ))
            return {
                data: data.faqs as any[],
                total: data.total!
            }
        },
        create: async (_, { data }) => {
            const { data: result } = await faqsApi.createFaq({
                value: data.value,
                query: data.query
            })

            return {
                data: result as any
            }
        },
        update: async (_, { data, id }) => {
            await faqsApi.updateFaq(id.toString(), { query: data.query, value: data.value })
            return {
                data: { id, ...data } as any
            }
        },
        delete: async (_, { previousData }) => {
            await faqsApi.deleteFaq(previousData!.id!.toString())
            return { data: { id: previousData!.id! } as any }
        },
        getOne: async (_, { id }) => {
            const faq = faqsCache.current.find(c => c.id === id)
            return {
                data: faq as any
            }
        }
    }
}

export default useFaqsProvider
