import axios from "axios";
import { initializeApp } from "firebase/app"
import { browserLocalPersistence, getAuth, onAuthStateChanged, signInWithEmailAndPassword, User } from "firebase/auth"
import { AuthProvider } from "react-admin";
import firebaseConfig from '../utils/firebase.config.json'

const app = initializeApp(firebaseConfig)
const auth = getAuth(app)

let waitFotAuthInit = new Promise<User | null>(
    resolve => {
        onAuthStateChanged(auth, resolve)
    }
)

const authProvider: AuthProvider & { getToken: () => Promise<string> } = {
    login: async ({ password, email }: { email: string, password: string }) => {
        await auth.setPersistence(browserLocalPersistence)
        const { user } = await signInWithEmailAndPassword(auth, email, password)
        const idToken = await user.getIdTokenResult()

        const canAccessAdminPanel = idToken.claims.type === "admin" || idToken.claims.type === "mod"

        if(!canAccessAdminPanel) {
            auth.signOut()
            throw new Error('Forbidden')
        }

        waitFotAuthInit = Promise.resolve(user)
    },
    logout: async() => {
        await auth.signOut()
        waitFotAuthInit = Promise.resolve(null)
    },
    checkAuth: async() => {
        const user = await waitFotAuthInit
        if(!user) {
            throw new Error('Please login to continue')
        }
    },
    checkError: async error => {
        if(axios.isAxiosError(error)) {
            const status = error.response?.status
            if(status === 401) {
                throw new Error('Please login to continue')
            }
        }
    },
    getPermissions: async() => {

    },
    getToken: async () => {
        const user = await waitFotAuthInit
        if(user) {
            return user.getIdToken()
        }
        throw new Error('Please login to continue')
    }
}

export default authProvider;
