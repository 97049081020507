import React from 'react'
import {
    Create,
    CreateButton,
    Datagrid,
    DeleteWithConfirmButton,
    Edit,
    EditButton,
    ExportButton, FileInput, ImageField,
    List,
    maxLength,
    minLength,
    Pagination,
    required,
    ResourceComponentProps,
    sanitizeListRestProps,
    Show,
    ShowButton,
    SimpleForm,
    Tab,
    TabbedShowLayout,
    TextField,
    TextInput,
    TopToolbar,
    useListContext,
    useNotify,
    useRedirect,
    useRefresh
} from 'react-admin'

const ListActions = (props: any) => {
    const { className, exporter, filters, maxResults, ...rest } = props
    const { currentSort, resource, filterValues, basePath, total } = useListContext()
    return (
        <TopToolbar className={ className } { ...sanitizeListRestProps(rest) }>
            <CreateButton basePath={ basePath }/>
            <ExportButton disabled={ total === 0 } resource={ resource } sort={ currentSort }
                          filterValues={ filterValues } maxResults={ maxResults }/>
        </TopToolbar>
    )
}

export const ChatroomList = (props: any) => {
    return (
        <>
            <List
                { ...props }
                actions={ <ListActions/> }
                perPage={ 25 }
                pagination={ <Pagination rowsPerPageOptions={ [ 25, 50, 100 ] }/> }
                title="Chatrooms"
                bulkActionButtons={ false }
            >
                <Datagrid>
                    <ImageField source="thumbnail" className='small-img'/>
                    <TextField source="title"/>
                    <ShowButton/>
                    <EditButton/>
                    <DeleteWithConfirmButton label=''/>
                </Datagrid>
            </List>
        </>
    )
}

export const ChatroomCreate = (props: ResourceComponentProps) => {

    const notify = useNotify()
    const redirect = useRedirect()
    const refresh = useRefresh()

    const onSuccess = () => {
        notify(`Successfully Created`)
        redirect('/chatroom')
        refresh()
    }

    return (
        <Create onSuccess={ onSuccess } { ...props }>
            <SimpleForm submitOnEnter={ false }>
                <TextInput
                    source="title"
                    label="Chatroom Title"
                    validate={ [ required(), maxLength(256), minLength(1) ] }
                    fullWidth
                />
                <FileInput
                    source="thumbnail"
                    label="Thumbnail for Chatroom"
                    accept="image/*"
                    validate={[required()]}
                >
                    <ImageField source="src" title="Preview" fullWidth />
                </FileInput>

            </SimpleForm>
        </Create>
    )
}


export const ChatroomEdit = (props: ResourceComponentProps) => {

    return (
        <Edit { ...props }>
            <SimpleForm submitOnEnter={ false }>
                <TextInput
                    source="title"
                    label="Chatroom Title"

                    validate={ [ required(), maxLength(256), minLength(1) ] }
                    fullWidth
                />
                <FileInput
                    source="thumbnail"
                    label="Thumbnail for Chatroom"
                    accept="image/*"
                    validate={[required()]}
                >
                    <ImageField source="src" title="Preview" fullWidth />
                </FileInput>
            </SimpleForm>
        </Edit>
    )
}

export const ChatroomShow = (props: ResourceComponentProps) => {

    return (
        <Show { ...props }>
            <TabbedShowLayout>
                <Tab label="Chatrooms">
                    <TextField label='Chatroom Title' source='title'/>
                    <ImageField source="thumbnail" className='small-img'/>
                </Tab>
            </TabbedShowLayout>
        </Show>
    )
}
