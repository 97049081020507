import { createTheme } from '@material-ui/core';
import typography from './typography';

const theme = createTheme({
    palette: {
        background: {
            default: '#f5f5f5',
        },
        primary: {
            main: '#f08b11'
        },
        secondary: {
            main: '#a16020'
        },
        text: {
            primary: '#ee9c38',
            secondary: '#d68b40',
        }
    },
    typography
});

export default theme;
