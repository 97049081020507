import { Category, CategoryApi, CategoryUpdate } from "@lj-island/peachy-religion-client";
import { useMemo, useRef } from "react"
import s3Provider from "./s3-provider";
import { SimpleDataProvider } from "./types";

const useCategoriesProvider: SimpleDataProvider = (config) => {
	const categoriesApi = useMemo(() => new CategoryApi(config), [config])
	const uploadApi = useMemo(() => s3Provider(config), [config])

	const categoriesCache = useRef<Category[]>([])

	return {
		getMany: async (_, {ids}) => {
			const category = categoriesCache.current.find(c => ids.includes(c.id)) // dummy implementation for video ref
			return {
				data: [category] as any
			}
		},
		getList: async function(_, params) {
			const { data } = await categoriesApi.getCategories('true', params.pagination.perPage, params.pagination.page)
			categoriesCache.current = data.categories.map(c => ({ ...c }))
			return {
				data: data.categories as any[],
				total: data.total!
			}
		},
		create: async(_, { data }) => {
			const uploadedUrl = await uploadApi.upload(data.thumbnail.rawFile)
			const { data: result } = await categoriesApi.createCategory({
				name: data.name,
				thumbnail: uploadedUrl
			})

			return {
				data: result as any
			}
		},
		update: async(_, { data, id }) => {
			const req: CategoryUpdate = { }
			if(data.name) {
				req.name = data.name
			}
			if(data.thumbnail?.rawFile) {
				req.thumbnail = await uploadApi.upload(data.thumbnail.rawFile)
			}
			if(data.rank) {
				req.rank = data.rank
			}

			await categoriesApi.updateCategory(id.toString(), req)
			return {
				data: { id, ...data } as any
			}
		},
		delete: async(_, { previousData }) => {
			await categoriesApi.deleteCategory(previousData!.id!.toString())
			return { data: { id: previousData!.id } as any }
		},
		getOne: async(_, { id }) => {
			const category = categoriesCache.current.find(c => c.id === id)
			return {
				data: category as any
			}
		}
	}
}

export default useCategoriesProvider
