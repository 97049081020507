import { ChatRoom, ChatroomApi, ChatRoomUpdate } from "@lj-island/peachy-religion-client";
import { useMemo, useRef } from "react"
import { SimpleDataProvider } from "./types";
import s3Provider from "./s3-provider";

const useChatroomsProvider: SimpleDataProvider = (config) => {
    const chatroomsApi = useMemo(() => new ChatroomApi(config), [ config ])
    const uploadApi = useMemo(() => s3Provider(config), [ config ])

    const chatroomsCache = useRef<ChatRoom[]>([])

    return {
        getList: async function (_, params) {
            const { data } = await chatroomsApi.getChatrooms('true', undefined, params.pagination.page, params.pagination.perPage)
            chatroomsCache.current = data.chatrooms.map(c => ( { ...c } ))
            return {
                data: data.chatrooms as any[],
                total: data.total!
            }
        },
        create: async (_, { data }) => {
            const uploadedUrl = await uploadApi.upload(data.thumbnail.rawFile)
            const { data: result } = await chatroomsApi.createChatroom({
                title: data.title,
                thumbnail: uploadedUrl
            })

            return {
                data: result as any
            }
        },
        update: async (_, { data, id }) => {
            const req: ChatRoomUpdate = {}
            if ( data.title ) {
                req.title = data.title
            }
            if ( data.thumbnail?.rawFile ) {
                req.thumbnail = await uploadApi.upload(data.thumbnail.rawFile)
            }
            await chatroomsApi.updateChatroom(id.toString(), req)
            return {
                data: { id, ...data } as any
            }
        },
        delete: async (_, { previousData }) => {
            await chatroomsApi.deleteChatroom(previousData!.id!.toString())
            return { data: { id: previousData!.id! } as any }
        },
        getOne: async (_, { id }) => {
            const faq = chatroomsCache.current.find(c => c.id === id)
            return {
                data: faq as any
            }
        }
    }
}

export default useChatroomsProvider
