import { Configuration } from "@lj-island/peachy-religion-client";
import { useMemo } from "react";
import { DataProvider } from "react-admin";
import { BASE_URL } from "../utils/constants";
import useCategoriesProvider from './use-categories-provider'
import useUsersProvider from "./use-users-provider";
import useVideosProvider from "./use-videos-provider";
import useFaqsProvider from "./use-faq-provider";
import useChatroomsProvider from "./use-chatrooms-provider";

type ResourceType = 'category' | 'video' | 'user' | 'faq' | 'chatroom'

const DATA_PROVIDER_FUNCTIONS: (keyof DataProvider)[] = [
    'getList',
    'getOne',
    'getMany',
    'getManyReference',
    'create',
    'update',
    'updateMany',
    'delete',
    'deleteMany'
]

const useDataProvider = (getToken: () => Promise<string>): DataProvider => {

	const config = useMemo(() =>
        new Configuration({ accessToken: () => getToken(), basePath: BASE_URL }),
        [getToken]
    )

    const category = useCategoriesProvider(config)
    const video = useVideosProvider(config)
    const faq = useFaqsProvider(config)
    const user = useUsersProvider(config)
    const chatroom = useChatroomsProvider(config)

    const map: { [T in ResourceType]: Partial<DataProvider> } = {
        category,
        chatroom,
        video,
        faq,
        user
    }

    const makeMapFunction = (func: keyof DataProvider) => {
        return async(resource: string, ...args: any[]) => {
            const item = map[resource as ResourceType]
            if(!item) {
                throw new Error(`invalid resource "${resource}"`)
            }
            if(typeof item[func] !== 'function') {
                throw new Error(`unsupported function "${func}" in "${resource}"`)
            }
            return item[func]('', ...args)
        }
    }

    const provider = DATA_PROVIDER_FUNCTIONS.reduce(
        (dict, key) => {
            dict[key] = makeMapFunction(key)
            return dict
        }, { } as any
    )

    return provider
}

export default useDataProvider
