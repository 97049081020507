import React, {useState} from 'react';
import {Box, Button, CardMedia, Container, makeStyles, TextField, Typography} from '@material-ui/core';
import {Notification, useLogin, useNotify, useRedirect} from 'react-admin';


const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.light,
        height: 'min-content',
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3)
    },
    image: {
        width: 120,
        height: 120,
        marginRight: 'auto',
        marginLeft: 'auto',
    },
    inputTextColor: {
        color: theme.palette.text.secondary
    }
}));

const Login = () => {
    const classes = useStyles();
    const login = useLogin();
    const notify = useNotify();
    const redirect = useRedirect();

    if (localStorage.getItem('idToken')) {
        redirect('/');
    }

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const handleSubmit = e => {
        e.preventDefault();
        login({email, password})
            .then(() => {
                notify("Successful Login.");
            })
            .catch(() => {
                notify("Invalid Email and Password.",'error');
            });
    };

    return (
        <div
            className={classes.root}
        >
            <Box
                display="flex"
                flexDirection="column"
                height="100%"
                justifyContent="center"
            >
                <Container maxWidth="sm">
                    <CardMedia
                        className={classes.image}
                        title="Peachy Religion"
                        image="/logo_png.png"
                        media="img"
                    />
                    <Box mb={3}>
                        <Typography
                            color="primary"
                            variant="h2"
                            align="center"
                        >
                            Peachy Religion Login
                        </Typography>
                    </Box>
                    <form
                        onSubmit={handleSubmit}
                    >
                        <TextField
                            fullWidth
                            label="Email"
                            margin="normal"
                            name="email"
                            onChange={e => setEmail(e.target.value)}
                            type="text"
                            value={email}
                            variant="outlined"
                            InputProps={{className: classes.inputTextColor}}
                        />
                        <TextField
                            fullWidth
                            label="Password"
                            margin="normal"
                            name="password"
                            onChange={e => setPassword(e.target.value)}
                            type="password"
                            value={password}
                            variant="outlined"
                            InputProps={{className: classes.inputTextColor}}
                        />
                        <Box my={2}>
                            <Button
                                color="primary"
                                fullWidth
                                size="large"
                                type="submit"
                                variant="contained"
                            >
                                Login
                            </Button>
                        </Box>
                    </form>
                    <Notification
                        autoHideDuration={3000}
                    />
                </Container>
            </Box>
        </div>
    );
};

export default Login;
