import { Video, VideoApi, VideoUpdate } from "@lj-island/peachy-religion-client";
import { useMemo, useRef } from "react"
import { SortPayload } from "react-admin";
import { SimpleDataProvider } from "./types";
import vimeoProvider from "./vimeo-provider";
import s3Provider from "./s3-provider";

const getVideoSort = ({ field, order }: SortPayload) => {
    if ( field === 'createdAt' ) return order === 'ASC' ? 'oldest' : 'recent'
    if ( field === 'likeCount' ) return 'likes'
    if ( field === 'viewCount' ) return 'views'
}

const loadVideo = (file: File): Promise<any> => new Promise((resolve, reject) => {
    try {
        let video: HTMLVideoElement = document.createElement('video')
        video.preload = 'metadata'
        video.onloadedmetadata = function () {
            resolve(this)
        }
        video.onerror = function () {
            reject(false)
        }
        video.src = window.URL.createObjectURL(file)
    } catch ( e ) {
        reject(false)
    }
})

const useVideosProvider: SimpleDataProvider = (config) => {

    const videosApi = useMemo(() => new VideoApi(config), [ config ])
    const uploadApi = useMemo(() => s3Provider(config), [ config ])
    const vimeoApi = useMemo(() => vimeoProvider(), [])

    const videosCache = useRef<Video[]>([])

    return {
        delete: async (_, { previousData }) => {
            await videosApi.deleteVideo(previousData!.id!.toString())
            return { data: { id: previousData!.id! } as any }
        },
        update: async (_, { previousData, data, id }) => {
            const req: VideoUpdate = {}

            if ( data.title !== previousData.title ) {
                req.title = data.title
            }
            if ( data.hasGiveaway ) {
                req.giveaway = data.giveaway
            }
            if ( previousData.category?.id !== data.category?.id ) {
                req.category = data.category.id
            }
            let rawVideo
            if ( data.video?.rawFile ) {
                const videoWithMeta = await loadVideo(data.video.rawFile)
                rawVideo = data.video.rawFile
                req.fileSize = data.video?.rawFile.size
                req.duration = Math.ceil(videoWithMeta.duration)
            }

            if ( data.thumbnail?.rawFile ) {
                req.thumbnail = await uploadApi.upload(data.thumbnail.rawFile)
            }

            const { data: updateResponse } = await videosApi.updateVideo(id.toString(), req)

            if ( updateResponse.vimeoUploadUrl ) {
                await vimeoApi.upload(rawVideo, updateResponse.vimeoUploadUrl)
            }

            if(updateResponse.thumbnailUpload?.link){

                await vimeoApi.uploadThumbnail(updateResponse.thumbnailUpload.link, data.thumbnail.rawFile)

                await videosApi.makeThumbnailActive(updateResponse.thumbnailUpload.picUri)
            }

            return {
                data: { id, ...data } as any
            }
        },
        getList: async function (_, params) {
            const { data } = await videosApi.getVideos(
                'true',
                undefined,
                undefined,
                params.filter.q,
                getVideoSort(params.sort),
                params.filter.giveawaysOnly || undefined,
                params.pagination.perPage,
                params.pagination.page
            )
            videosCache.current = data.videos.map(v => ( { ...v } ))
            return {
                data: data.videos as any[],
                total: data.total!
            }
        },
        create: async function (_, { data }) {
            const rawVideo = data.video.rawFile

            const video = await loadVideo(rawVideo)

            const duration = Math.ceil(video.duration)

            const uploadedUrl = await uploadApi.upload(data.thumbnail.rawFile)

            const { data: result } = await videosApi.createVideo({
                duration,
                fileSize: data.video.rawFile.size,
                category: data.category?.id,
                giveaway: data.hasGiveaway ? {
                    startDate: data.startDate,
                    endDate: data.endDate,
                    announcementDate: data.announcementDate,
                    description: data.description,
                } : undefined,
                title: data.title,
                thumbnail: uploadedUrl
            })

            await vimeoApi.upload(rawVideo, result.vimeoUploadUrl)

            const { data: thumbnailResp } = await videosApi.getThumbnailLink(result.vimeoId)

            await vimeoApi.uploadThumbnail(thumbnailResp.link, data.thumbnail.rawFile)

            await videosApi.makeThumbnailActive(thumbnailResp.picUri)

            return { data: result as any }
        },
        getOne: async (_, { id }) => {
            const video = videosCache.current.find(v => v.id === id)
            return {
                data: video as any
            }
        }
    }
}

export default useVideosProvider
