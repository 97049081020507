import { InlineObject2ActionEnum, UserApi, UserUpdate } from "@lj-island/peachy-religion-client";
import { useMemo } from "react"
import s3Provider from "./s3-provider";
import { SimpleDataProvider } from "./types";

const useUsersProvider: SimpleDataProvider = (config) => {
	const usersApi = useMemo(() => new UserApi(config), [config])
	const uploadApi = useMemo(() => s3Provider(config), [config])

	return {
		getMany: async (_, {ids}) => {
			throw new Error('')
		},
		getList: async function(_, { filter, pagination }) {
			const { data } = await usersApi.getUser(filter.q, undefined, 'true', pagination.perPage, pagination.page)
			return {
				data: data.users as any[],
				total: data.total!
			}
		},
		create: async(_, { data }) => {
			const { data: user } = await usersApi.adminCreateUser(data)
			return {
				data: user as any
			}
		},
		update: async(_, { data, previousData, id }) => {
			const update: UserUpdate = { }
			if(data.username !== previousData.username) {
				update.username = data.username
			}
			if(data.email !== previousData.email) {
				update.email = data.email
			}
			if(data.name !== previousData.name) {
				update.name = data.name
			}
			if(data.type !== previousData.type) {
				update.type = data.type
			}
			if(data.password) {
				throw new Error('TODO')
			}
			if(data.profilePic !== previousData.profilePic) {
				update.profilePic = await uploadApi.upload(data.profilePic.rawFile)
			}

			if(typeof data.blocked !== 'undefined') {
				await usersApi.blockUser(
					data.id, 
					{ 
						action: data.blocked ? InlineObject2ActionEnum.Block : InlineObject2ActionEnum.Unblock
					}
				)
			}

			if(Object.keys(update).length) {
				await usersApi.updateUser(
					id.toString(),
					update
				)
			}

			return {
				data: { ...data, id }
			}
		},
		delete: async(_, { previousData }) => {
			throw new Error('Not Supported')
		},
		getOne: async(_, { id }) => {
			const { data: { users } } = await usersApi.getUser(undefined, [id.toString()], undefined, 1, undefined)
			if(!users[0]) {
				throw new Error('user not found')
			}
			return {
				data: users[0] as any
			}
		}
	}
}

export default useUsersProvider
