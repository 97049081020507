import React from 'react'
import { BooleanField, Button, ChipField, Confirm, Create, CreateButton, Datagrid, DateField, Edit, EditButton, email, ExportButton, FileInput, Filter, FunctionField, ImageField, List, maxLength, minLength, Mutation, Pagination, PasswordInput, required, ResourceComponentProps, sanitizeListRestProps, SearchInput, SelectInput, ShowButton, SimpleForm, TextField, TextInput, TopToolbar, useListContext, useNotify, useRedirect, useRefresh } from 'react-admin'

const ListActions = (props: any) => {
	const { className, exporter, filters, maxResults, ...rest } = props
	const { currentSort, resource, filterValues, basePath, total } = useListContext()
	return (
	  <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
		<CreateButton basePath={basePath} />
		<ExportButton disabled={total === 0} resource={resource} sort={currentSort} filterValues={filterValues} maxResults={maxResults} />
	  </TopToolbar>
	)
}

const UserTypeInput = () => (
	<SelectInput
		source="type"
		label="User Type"
		choices={[
			{ id: 'user', name: 'Regular User' },
			{ id: 'mod', name: 'Moderator' },
			{ id: 'admin', name: 'Admin' }
		]}
	/>
)

const UserFilter = (props: any) => {
    return (
        <Filter {...props}>
            <SearchInput
                fullWidth
                source="q"
                alwaysOn
                variant="outlined"
            />
        </Filter>
    )
}

const UserBlockButton = () => (
	<FunctionField 
		label='Block'
		render={
			(item: any) => (
				<Mutation
					type="update"
					resource="user"
					payload={{ id: item.id, data: { ...item, blocked: !item.blocked }, previousData: { ...item } }}
					options={{
						undoable: false,
						onSuccess: {
							notification: { body: item.blocked ? 'User unblocked' : 'User blocked', level: 'info' },
							redirectTo: '/user',
						},
						onFailure: {
							notification: { body: 'Error: failed to block user', level: 'warning' }
						}
					}}
				>
					{(block) => (
						<Button label={item.blocked ? 'Unblock' : 'Block'} onClick={block}/>
					)}
				</Mutation>
			)
		}/>
)

export const UserList = (props: any) => {
	return (
	  <>
		<List
		  {...props}
		  sort={{ field: 'createdAt', order: 'DESC' }}
		  actions={<ListActions />}
		  filters={<UserFilter />}
		  perPage={25}
		  pagination={<Pagination rowsPerPageOptions={[25, 50, 100]} />}
		  title="Users"
		  bulkActionButtons={false}
		>
		  <Datagrid>
			<FunctionField 
				label="Type" 
				render={(user: any) => {
					if(user.type !== 'user') {
						return <ChipField source="type" />
					}
				}}/>
			<ImageField source="profilePic" className='small-img-pp' label="Display" />
			<TextField source="id" />
			<TextField source="username" />
			<DateField source="createdAt" label="Created" />
			<TextField source="name" />
			<TextField source="email" />

			<BooleanField source="blocked" />
			<BooleanField source="deleted" />

			<UserBlockButton />

			<ShowButton label='' />
			<EditButton label='' />
		  </Datagrid>
		</List>
	  </>
	)
}

export const UserCreate = (props: ResourceComponentProps) => {

    const notify = useNotify()
    const redirect = useRedirect()
    const refresh = useRefresh()

	const onSuccess = () => {
		notify(`Successfully Created`)
        redirect('/user')
        refresh()
	}

    return (
        <Create onSuccess={onSuccess} {...props}>
            <SimpleForm submitOnEnter={false}>
                <TextInput
                    source="name"
					label="Full Name"
                	validate={[required(), maxLength(128), minLength(1)]}
                    fullWidth
                />
				<TextInput
                    source="username"
					label="Username"
                	validate={[required(), maxLength(128), minLength(1)]}
                    fullWidth
                />
				<TextInput
                    source="email"
					label="Email Address"
                	validate={[email(), required()]}
                    fullWidth
                />
				<PasswordInput
                    source="password"
					label="Password"
                	validate={[required()]}
                    fullWidth
                />
				<UserTypeInput />
				<FileInput
					source="profilePic"
					label="Display Picture"
					accept="image/*"
				>
					<ImageField source="src" title="Preview" fullWidth />
				</FileInput>
            </SimpleForm>
        </Create>
    )
}

export const UserEdit = (props: ResourceComponentProps) => {
    return (
        <Edit {...props}>
            <SimpleForm submitOnEnter={false}>
                <TextInput
                    source="name"
					label="Full Name"
                	validate={[required(), maxLength(128), minLength(1)]}
                    fullWidth
                />
				<TextInput
                    source="username"
					label="Username"
                	validate={[required(), maxLength(128), minLength(1)]}
                    fullWidth
                />
				<TextInput
                    source="email"
					label="Email Address"
                	validate={[email(), required()]}
                    fullWidth
                />
				<PasswordInput
                    source="password"
					label="Password"
                    fullWidth
                />
				<UserTypeInput />
				<FileInput
					source="profilePic"
					label="Display Picture"
					accept="image/*"
				>
					<ImageField source="src" title="Preview" fullWidth />
				</FileInput>
            </SimpleForm>
        </Edit>
    )
}
