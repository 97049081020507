import React from 'react'
import { Create, CreateButton, Datagrid, DeleteButton, DeleteWithConfirmButton, Edit, EditButton, ExportButton, FileInput, ImageField, List, maxLength, minLength, NumberField, NumberInput, Pagination, required, ResourceComponentProps, sanitizeListRestProps, Show, ShowButton, SimpleForm, Tab, TabbedShowLayout, TextField, TextInput, TopToolbar, useListContext, useNotify, useRedirect, useRefresh } from 'react-admin'

const ListActions = (props: any) => {
	const { className, exporter, filters, maxResults, ...rest } = props
	const { currentSort, resource, filterValues, basePath, total } = useListContext()
	return (
	  <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
		<CreateButton basePath={basePath} />
		<ExportButton disabled={total === 0} resource={resource} sort={currentSort} filterValues={filterValues} maxResults={maxResults} />
	  </TopToolbar>
	)
  }

export const CategoryList = (props: any) => {
	return (
	  <>
		<List
		  {...props}
		  sort={{ field: 'rank', order: 'ASC' }}
		  actions={<ListActions />}
		  perPage={25}
		  pagination={<Pagination rowsPerPageOptions={[25, 50, 100]} />}
		  title="Categories"
		  bulkActionButtons={false}
		>
		  <Datagrid>
			<ImageField source="thumbnail" className='small-img'/>
			<TextField source="id" />
			<TextField source="name" />
			<TextField source="rank"/>
			<TextField source="videoCount" label="Number of Videos" />
			<ShowButton />
			<EditButton />
			<DeleteWithConfirmButton label='' />
		  </Datagrid>
		</List>
	  </>
	)
}

export const CategoryCreate = (props: ResourceComponentProps) => {

    const notify = useNotify()
    const redirect = useRedirect()
    const refresh = useRefresh()

	const onSuccess = () => {
		notify(`Successfully Created`)
        redirect('/category')
        refresh()
	}

    return (
        <Create onSuccess={onSuccess} {...props}>
            <SimpleForm submitOnEnter={false}>
                <TextInput
                    source="name"
					label="Category Title"

                	validate={[required(), maxLength(128), minLength(1)]}
                    fullWidth
                />
				<FileInput
					source="thumbnail"
					label="Thumbnail for Category"
					accept="image/*"
					validate={[required()]}
				>
					<ImageField source="src" title="Preview" fullWidth />
				</FileInput>
            </SimpleForm>
        </Create>
    )
}


export const CategoryEdit = (props: ResourceComponentProps) => {

    return (
        <Edit {...props}>
            <SimpleForm submitOnEnter={false}>
                <TextInput
                    source="name"
					label="Category Title"

                	validate={[required(), maxLength(128), minLength(1)]}
                    fullWidth
                />
				<NumberInput
					source="rank"
					label="Category Rank (Sorting Order)"
					fullWidth
				/>
				<FileInput
					source="thumbnail"
					label="Thumbnail for Category"
					accept="image/*"
					validate={[required()]}
				>
					<ImageField source="src" title="Preview" fullWidth />
				</FileInput>
            </SimpleForm>
        </Edit>
    )
}

export const CategoryShow = (props: ResourceComponentProps) => {

	return (
		<Show {...props}>
            <TabbedShowLayout>
                <Tab label="Categories">
					<TextField label='Category Name' source='name'/>
					<NumberField label='Number of Videos' source='videoCount'/>

                </Tab>
			</TabbedShowLayout>
		</Show>
	)
}
