import React from 'react'
import {
    BooleanField,
    BooleanInput,
    Create,
    CreateButton,
    Datagrid,
    DateField,
    DateTimeInput,
    DeleteWithConfirmButton,
    Edit,
    EditButton,
    ExportButton,
    FileField,
    FileInput,
    Filter,
    FormDataConsumer,
    FunctionField,
    ImageField,
    List,
    maxLength,
    minLength,
    NumberField,
    Pagination,
    ReferenceInput,
    required,
    ResourceComponentProps,
    sanitizeListRestProps,
    SearchInput,
    SelectInput,
    Show,
    ShowButton,
    SimpleForm,
    Tab,
    TabbedShowLayout,
    TextField,
    TextInput,
    TopToolbar,
    useListContext,
    useNotify,
    useRedirect,
    useRefresh,
    useShowController
} from 'react-admin'

const formatDurationS = (durationS: number) => {
    const m = Math.floor(durationS/60)
    const s = durationS-(m*60)

    return `${m}m ${s}s`
}

const VideoFilter = (props: any) => {
    return (
        <Filter {...props}>
            <SearchInput
                fullWidth
                source="q"
                alwaysOn
                variant="outlined"
            />
            <BooleanInput 
                source="giveawaysOnly"
                alwaysOn
                variant="outlined"/>
        </Filter>
    )
}

const ListActions = (props: any) => {
    const { className, exporter, filters, maxResults, ...rest } = props
    const { currentSort, resource, filterValues, basePath, total } = useListContext()
    return (
        <TopToolbar className={ className } { ...sanitizeListRestProps(rest) }>
            <CreateButton basePath={ basePath }/>
            <ExportButton disabled={ total === 0 } resource={ resource } sort={ currentSort }
                          filterValues={ filterValues } maxResults={ maxResults }/>
        </TopToolbar>
    )
}

export const VideoList = (props: any) => {
    return (
        <>
            <List
                { ...props }
                actions={ <ListActions/> }
                filters={<VideoFilter />}
                sort={ { order: 'DESC', field: 'createdAt' } }
                perPage={ 25 }
                pagination={ <Pagination rowsPerPageOptions={ [ 25, 50, 100 ] }/> }
                title="Videos"
                bulkActionButtons={ false }
            >
                <Datagrid>
                    <ImageField source="thumbnail" className='small-img'/>

                    <TextField source="id"/>
                    <TextField source="title"/>
                    <DateField source="createdAt"/>
                    <TextField source="likeCount" label="Likes"/>
                    <TextField source="viewCount" label="Views"/>
                    <TextField source="category.name" label={ "Category" }/>
                    <BooleanField source="isGiveawayVideo" label="Has Giveaway"/>

                    <FunctionField
                        label="Duration"
                        render={(item: any) => {
                            return formatDurationS(item.duration)
                        }}/>

                    <ShowButton/>
                    <EditButton/>
                    <DeleteWithConfirmButton label=''/>
                </Datagrid>
            </List>
        </>
    )
}

const VideoCategoryPicker = () => {
    return (
        <ReferenceInput
            source="category.id"
            label='Category'
            reference="category"
            allowEmpty={true}
            perPage={ 100 }
        >
            <SelectInput optionText="name"/>
        </ReferenceInput>
    )
}

export const VideoCreate = (props: ResourceComponentProps) => {
    const notify = useNotify()
    const redirect = useRedirect()
    const refresh = useRefresh()

    const onSuccess = () => {
        notify(`Successfully Created`)
        redirect('/video')
        refresh()
    }

    return (
        <Create onSuccess={ onSuccess } { ...props }>
            <SimpleForm submitOnEnter={ false }>
                <TextInput
                    source="title"
                    label="Video Title"
                    validate={ [ required(), maxLength(128), minLength(1) ] }
                    fullWidth
                />
                <VideoCategoryPicker />
                <BooleanInput source="hasGiveaway"/>
                <FormDataConsumer>
                    { ({ formData, ...rest }) => formData.hasGiveaway &&
                        <>
                            <TextInput source="description" { ...rest }/>
                            <br/>
                            <DateTimeInput source="startDate" { ...rest } />
                            <br/>
                            <DateTimeInput source="announcementDate" { ...rest } />
                            <br/>
                            <DateTimeInput source="endDate" { ...rest } />
                        </>
                    }
                </FormDataConsumer>
                <FileInput
                    source="thumbnail"
                    label="Thumbnail for Video"
                    accept="image/*"
                    validate={ [ required() ] }
                >
                    <ImageField source="src" title="Preview" fullWidth/>
                </FileInput>
                <FileInput
                    source="video"
                    label="Video to be uploaded"
                    accept="video/*"
                    validate={ [ required() ] }
                >
                    <FileField source="src" title="Preview" fullWidth/>
                </FileInput>
            </SimpleForm>
        </Create>
    )
}


export const VideoEdit = (props: ResourceComponentProps) => {
    return (
        <Edit { ...props }>
            <SimpleForm submitOnEnter={ false }>
                <TextInput
                    source="title"
                    label="Video Title"
                    validate={ [ required(), maxLength(256), minLength(1) ] }
                    fullWidth
                />
                <VideoCategoryPicker />
                <BooleanInput source="hasGiveaway"/>
                <FormDataConsumer>
                    { ({ formData, ...rest }) => formData.hasGiveaway &&
                        <>
                            <TextInput source="description" { ...rest }/>
                            <br/>
                            <DateTimeInput source="startDate" { ...rest } />
                            <br/>
                            <DateTimeInput source="announcementDate" { ...rest } />
                            <br/>
                            <DateTimeInput source="endDate" { ...rest } />
                        </>
                    }
                </FormDataConsumer>
                <FileInput
                    source="thumbnail"
                    label="Thumbnail for Video"
                    accept="image/*"
                    validate={ [] }
                >
                    <ImageField source="src" title="Preview" fullWidth/>
                </FileInput>
                <FileInput
                    source="video"
                    label="Video to be uploaded"
                    accept="video/*"
                    validate={ [] }
                >
                    <FileField source="src" title="Preview" fullWidth/>
                </FileInput>
            </SimpleForm>
        </Edit>
    )
}

export const VideoShow = (props: ResourceComponentProps) => {
    const { record } = useShowController(props);

    return (
        <Show { ...props }>
            <TabbedShowLayout>
                <Tab label="Videos">
                    <TextField label='Video Title' source='title'/>
                    <TextField label='Category Name' source='category.name'/>
                    <DateField label='Created At' source='createdAt'/>
                    { record?.isGiveawayVideo &&
                    <TextField label='Giveaway Description'
                               source='giveaway.description'/> }
                    { record?.isGiveawayVideo &&
                    <DateField label='Announcement Date'
                               source='giveaway.announcementDate'/> }
                    { record?.isGiveawayVideo &&
                    <DateField label='Start Date'
                               source='giveaway.startDate'/> }
                    { record?.isGiveawayVideo &&
                    <DateField label='End Date'
                               source='giveaway.endDate'/> }
                    <NumberField label='Number of Views' source='viewCount'/>
                    <NumberField label='Number of Likes' source='likeCount'/>
                    <ImageField source="thumbnail" title="Thumbnail" fullWidth/>
                    <FileField source="videoUrl" title="Video" fullWidth/>
                </Tab>
            </TabbedShowLayout>
        </Show>
    )
}
